<template>
  <div class="body">
    <el-container>
      <el-header>
        <!-- <div class="logo"> <img ref="logotype" src='@/assets/qiyi-logo.png' alt=""></div> -->
        <div class="faMat">
          <div class="logo">
            <img ref="logotype" src='https://co.vip.iqiyi.com/logo.svg' alt="">
          </div>
          <div class="fax">
            <div class="indSy">首页</div>
            <div class="indSy">合作模式</div>
            <div class="indSy">查询中心</div>
          </div>
        </div>



      </el-header>
      <el-container>
        <el-main>
          <div class="faInd">
            <div class="faSon1">订单查询</div>
            <el-tabs v-model="act" @tab-click="handleClick">
              <el-tab-pane label="直充订单查询" name="first">
                <div class="faSon2">
                  <div class="fasson">
                    <div class="datechoice">
                      <span style="color:#606266;font-size:14px">接口编号</span>
                      <el-input style="width: 200px;height: 43px;" v-model="phone" placeholder="请输入接口编号" size="medium"
                        required="true"></el-input>
                    </div>
                    <div class="datechoice">
                      <span style="color:#606266;font-size:14px">订单号</span>
                      <el-input style="width: 200px;height: 43px;" v-model="nameValue" placeholder="请输入订单号"
                        size="medium"></el-input>
                    </div>
                  </div>
                  <div class="emetyCla">
                    <div class="emtySon">
                      <div class="emty1">清空</div>
                      <div class="emty2">查询</div>
                    </div>

                  </div>
                </div>


                <el-table :data="tableData" border size="medium" style="width: 100%"
                  :header-cell-style="{ 'text-aline': 'center' }">
                  <!-- <el-table-column prop="port" label="接口编号" width="180" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.port" class="content" @click="scope.row.orderinput = false">{{
                        scope.row.port }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.port"
                          @keydown.enter="scope.row.orderinput = true" @change="scope.row.orderinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column> -->
                  <el-table-column prop="port" label="接口编号" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.portinput" class="content" @click="scope.row.portinput = false">{{
                        scope.row.port }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.port"
                          @keydown.enter="scope.row.portinput = true" @change="scope.row.portinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="phone" label="合作方订单号" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.phoneinput" class="content" @click="scope.row.phoneinput = false">{{
                        scope.row.phone }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.phone"
                          @keydown.enter="scope.row.phoneinput = true" @change="scope.row.phoneinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="brand" label="接口产品编号" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.brandinput" class="content" @click="scope.row.brandinput = false">{{
                        scope.row.brand }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.brand"
                          @keydown.enter="scope.row.brandinput = true" @change="scope.row.brandinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="goodsnamei" label="接口产品名称" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.goodsnameinput" class="content" @click="scope.row.goodsnameinput = false">{{
                        scope.row.goodsnamei }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.goodsnamei"
                          @keydown.enter="scope.row.goodsnameinput = true"
                          @change="scope.row.goodsnameinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="facevalue" label="价格（元）" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.facevalueinput" class="content" @click="scope.row.facevalueinput = false">{{
                        scope.row.facevalue }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.facevalue"
                          @keydown.enter="scope.row.facevalueinput = true"
                          @change="scope.row.facevalueinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="用户手机号" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.statusinput" class="content" @click="scope.row.statusinput = false">{{
                        scope.row.status }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.status"
                          @keydown.enter="scope.row.statusinput = true" @change="scope.row.statusinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time" label="结算价格" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.timeinput" class="content" @click="scope.row.timeinput = false">{{
                        scope.row.time }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time"
                          @keydown.enter="scope.row.timeinput = true" @change="scope.row.timeinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time1" label="订单支付时间" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.time1input" class="content" @click="scope.row.time1input = false">{{
                        scope.row.time1 }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time1"
                          @keydown.enter="scope.row.time1input = true" @change="scope.row.time1input = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time2" label="权益开始时间" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.time2input" class="content" @click="scope.row.time2input = false">{{
                        scope.row.time2 }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time2"
                          @keydown.enter="scope.row.time2input = true" @change="scope.row.time2input = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time3" label="权益结束时间" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.time3input" class="content" @click="scope.row.time3input = false">{{
                        scope.row.time3 }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time3"
                          @keydown.enter="scope.row.time3input = true" @change="scope.row.time3input = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time4" label="当前绑定手机号" width="127" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <p v-if="scope.row.time4input" class="content" @click="scope.row.time4input = false">{{
                        scope.row.time4 }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time4"
                          @keydown.enter="scope.row.time4input = true" @change="scope.row.time4input = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="phone" label="充值号码" width="200">
                    <template slot-scope="scope">
                      <p v-if="scope.row.phoneinput" class="content" @click="scope.row.phoneinput = false">{{
                        scope.row.phone }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.phone"
                          @keydown.enter="scope.row.phoneinput = true" @change="scope.row.phoneinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="brand" width="160" label="品牌名称">
                    <template slot-scope="scope">
                      <p v-if="scope.row.brandinput" class="content" @click="scope.row.brandinput = false">{{
                        scope.row.brand }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.brand"
                          @keydown.enter="scope.row.brandinput = true" @change="scope.row.brandinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="goodsname" width="320" label="商品名称">
                    <template slot-scope="scope">
                      <p v-if="scope.row.goodsnameinput" class="content" @click="scope.row.goodsnameinput = false">
                        {{ scope.row.goodsname }}</p>
                      <p v-else class="content"><el-input v-model="scope.row.goodsname"
                          @keydown.enter="scope.row.goodsnameinput = true"
                          @change="scope.row.goodsnameinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="facevalue" width="140" label="面值">
                    <template slot-scope="scope">
                      <p v-if="scope.row.facevalueinput" class="content" @click="scope.row.facevalueinput = false">
                        {{ scope.row.facevalue }}</p>
                      <p v-else class="content"><el-input v-model="scope.row.facevalue"
                          @keydown.enter="scope.row.facevalueinput = true"
                          @change="scope.row.facevalueinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" width="140" label="状态">
                    <template slot-scope="scope">
                      <p v-if="scope.row.statusinput" class="content" @click="scope.row.statusinput = false">{{
                        scope.row.status
                      }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.status"
                          @keydown.enter="scope.row.statusinput = true" @change="scope.row.statusinput = true"></el-input>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time" width="220" label="交易时间">
                    <template slot-scope="scope">
                      <p v-if="scope.row.timeinput" class="content" @click="scope.row.timeinput = false">{{ scope.row.time
                      }}
                      </p>
                      <p v-else class="content"><el-input v-model="scope.row.time"
                          @keydown.enter="scope.row.timeinput = true" @change="scope.row.timeinput = true"></el-input></p>
                    </template>
                  </el-table-column> -->
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="异常订单查询" name="second" disabled>异常订单查询</el-tab-pane>
            </el-tabs>

          </div>

        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>

export default {
  name: "txorder",
  data() {
    return {
      total: 0,//默认数据总数
      pagesize: 9,//每页的数据条数
      currentPage: 1,//默认开始页面
      goodsname: "",
      //搜索的商品名称
      goodsid: "",
      //搜索商品id
      tableData: [
        {
          order: '', phone: '', time: '', goodsname: '', brand: '', facevalue: '', status: '', time1: '',
          time2: '', time3: '', time4: '', port: '',
          orderinput: true, phoneinput: true, timeinput: true, goodsnameinput: true, brandinput: true, facevalueinput: true, statusinput: true, time1input: true, time2input: true, time3input: true, time4input: true, portinput: true
        }
      ],
      //供应商商品表格数据
      row_value: "",
      //点击行的值
      aside: true,
      phone: '',
      imgurl: '',
      act: 'first',
      nameValue: ''
    };
  },
  mounted() {

  },
  methods: {
    current_change: function (currentPage) {
      this.currentPage = currentPage;
    },
    //分页
  },
};
</script>
<style lang="less" scoped>
.bank_bind {
  width: 98%;
  min-height: 790px;
  margin: auto;
}

.title {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: #000;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}

.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block
}

.fy {
  text-align: center;
  margin-top: 10px;
}

.set {
  color: #3743D6;
  cursor: pointer;
  width: 100px;
}

.active {
  background: #3743D6;
  color: #fff;
}

// .el-button--primary{color: #fff;background-color: #3743D6;border-color: #3743D6;}
// .el-button--primary:hover{color: #fff;background-color: #3a8ee6;border-color: #3a8ee6;}
.logo {
  display: inline-block;
  width: 371px;
  height: 50px;
  line-height: 50px;

  img {
    height: 60px;
    width: 202px;
    vertical-align: middle;
  }
}

.namediv {
  float: right;
  min-width: 120px;
  font-size: 15px;
  margin-top: 25px;
}

.management-select {
  min-width: 168px;
  color: #333;
  height: 36px;
  padding: 0;
  margin-left: 36px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/sj.png) no-repeat scroll right 10px center transparent;
  padding-left: 11px;
  padding-right: 25px;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
}

.el-header {
  height: 60px !important;
  background-color: #1D2127;

}

.faMat {
  width: 1300px;
  margin: 0px auto;
}

.el-container {
  border-top: 1px solid #dadde0;
  height: 850px;
}

.el-main {
  position: relative;
  height: 100%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0;
  border-left: 1px solid #E2E2E2;
  overflow: initial;
}

.datechoice {
  // padding: 40px 48px 38px;
  padding: 31px 35px 38px;
  width: 100%;
  display: table;
  box-sizing: border-box;
}

.batch_recharge {
  font-size: 21px;
  height: 29px;
  line-height: 29px;
  color: #000;
  font-family: Microsoft YaHei;
  margin-left: 45px;
  margin-bottom: 35px;

  >span {
    cursor: pointer;
    display: inline-block;
    width: auto;
    margin: 0 5px;
  }

  >i {
    font-style: normal;
  }
}

.el-input {
  width: 300px;
  margin-left: 20px;
}

.body /deep/ .el-table__body-wrapper tr td {
  font-weight: 400;
  color: #909399;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  border-left: 0;
  border-right: 0;
}

.body /deep/ .has-gutter tr td {
  font-weight: normal;
  color: red;
}

.body /deep/ .el-table__header-wrapper tr th {
  font-weight: 400;
  color: #909399;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  background-color: #fafafa;
}

.body /deep/ .el-table__header-wrapper tr th:first-child {
  font-weight: 400;
  color: #909399;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  padding-left: 22px;
}

.body /deep/ .el-table__body-wrapper tr td:first-child {
  font-weight: 400;
  color: #909399;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  padding-left: 22px;
}

.content {
  width: 98%;
  cursor: pointer;
  height: 50px;
  line-height: 50px;

  .el-input {
    width: 96%;
    margin-left: 0 !important;
  }
}

select {
  font-size: 14px;
}

.fax {
  display: inline-block;
  // width: 500px;
  color: #fff;
  height: 50px;
  margin-left: 60px;
}

.indSy {
  display: inline-block;
  font-size: 14px;
  margin-left: 70px;
}

.faSon1 {
  font-size: 25px;
  font-weight: 800;
  color: #000;
}

.faInd {
  margin: 0px 15px;
  width: 1400px;
  margin: 0px auto;
}

.faSon2 {
  width: 100%;
  height: 150px;
  border: 1px solid #eee;
  margin-bottom: 15px;
}

.fasson {
  width: 900px;
  height: 100px;
  display: flex;
}

.fasson /deep/ .el-input__inner {
  height: 100%;
}

.emetyCla {
  width: 100%;
  // display: flex;
  // align-items: center;

}

.emtySon {
  width: 249px;
  margin: 0px auto;
}

.emty1 {
  width: 102px;
  height: 34px;
  line-height: 34px;
  background-color: #f9f9f9;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  border-radius: 3px;
  color: #bb8b51;
  border:1px solid #e3e3e3;
  cursor:pointer;

}
.emty2 {
  width: 102px;
  height: 34px;
  line-height: 34px;
  background-color: #f0d19e;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  margin-left: 45px;
  border-radius: 3px;
  cursor:pointer;

}
/deep/ .el-tabs__item{
  font-size: 17px;
  font-weight: 600;
}
/deep/ .el-tabs__item.is-active{
  font-size: 17px;
  font-weight: 600;
  color: #000;
}
/deep/ .el-tabs__active-bar{

  background-color: #bb8b51;
}
</style>